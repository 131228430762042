import * as React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { Helmet } from 'react-helmet';
import defaultOgImage from '../images/favicon.png';
import { useI18next } from 'gatsby-plugin-react-i18next';

//TODO: Zaktualizować dane, poprawic te cala funkcje

function Seo({ description, meta = [], title, ogImage }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const { language } = useI18next();
  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const metaOgImage = ogImage === undefined ? defaultOgImage : ogImage;

  return (
    <Helmet
      htmlAttributes={{
        lang: language
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `author`,
          content: site.siteMetadata.author
        },
        {
          name: `og:title`,
          content: defaultTitle
        },
        {
          name: `og:description`,
          content: metaDescription
        },
        {
          name: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          property: `og:image`,
          content: metaOgImage
        }
      ].concat(meta)}>
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "EducationalOrganization",
            "funder": [
              {
                "@type": "Person",
                "url": "https://www.linkedin.com/in/jakub-borowy/",
                "name": "Jakub Borowy",
                "@id": "http://schemaapp.com/db/MedBizInnovationsProgram#Person2"
              },
              {
                "@type": "Person",
                "url": "https://www.linkedin.com/in/kacper-raciborski/",
                "name": "Kacper Raciborski",
                "@id": "http://schemaapp.com/db/MedBizInnovationsProgram#Person3"
              }
            ],
            "parentOrganization": [
              {
                "@type": "Organization",
                "name": "Uniwersytecki Ośrodek Transferu Technologii UW",
                "logo": "http://uott.uw.edu.pl#ImageObject",
                "@id": "http://uott.uw.edu.pl"
              },
              {
                "@type": "Organization",
                "name": "Studenckie Koło Naukowe Biznesu SGH",
                "logo": "https://sknbiznes.pl#ImageObject",
                "@id": "https://sknbiznes.pl"
              }
            ],
            "address": {
              "@type": "PostalAddress",
              "name": "Aleja Niepodległości 162, 02-554 Warszawa",
              "addressCountry": "Polska",
              "addressLocality": "Warszawa",
              "addressRegion": "Mazowieckie",
              "postalCode": "02-554",
              "streetAddress": "Aleja Niepodległości 162",
              "@id": "https://goo.gl/maps/HdGwCTozZEQuWkQN8"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "name": "Informacja",
              "telephone": "+48 728 980 901",
              "availableLanguage": "English, Polish",
              "@id": "http://schemaapp.com/db/MedBizInnovationsProgram#ContactPoint"
            },
            "isAccessibleForFree": true,
            "name": "MedBiz Innovations Program",
            "description": "Naszym zadaniem jest łączenie studentów z kierunków technologicznych, biznesowych, naukowych, a także medycznych, dając im możliwość rozwoju i komercjalizacji swoich innowacyjnych pomysłów.",
            "email": "medbiz@sknbiznes.pl",
            "slogan": "Innovation of tomorrow is the idea of today",
            "@id": "http://schemaapp.com/db/MedBizInnovationsProgram#EducationalOrganization"
          }
        `}
      </script>
    </Helmet>
  );
}

export default Seo;
